<template>
  <div v-if="pageReady">
    <div
      class="p-5 m-5 d-flex flex-column justify-content-center align-items-center"
    >
      <img
        src="@/assets/img/referral-created.png"
        width="100"
        alt="tpd-created-icon"
      />
      <h2 class="mt-4 pb-2">TPD Completed</h2>

      <!-- selected services section -->
      <InfoBox :title="`Selected Service Requirement(s) & Fees`">
        <div class="container row mt-4">
          <div class="col">
            <p class="fw-bold">Service Requirement(s)</p>
            <ul>
              <li v-for="item in serviceAndFeesInfo" :key="item">
                {{ item }}
              </li>
            </ul>
          </div>
          <div class="col">
            <p class="fw-bold">Fees</p>
            <ul class="">
              <li>
                {{ fee }}
              </li>
            </ul>
          </div>
        </div>
      </InfoBox>

      <!-- lawyer details section -->
      <div id="patient_info_section" class="container my-5">
        <h2 class="pt-5 my-5 fs-3 text-dark">Referrer’s Details</h2>
        <div class="my-4 row">
          <div class="col">
            <BaseInputDisplay
              :label="'First Name'"
              :value="lawyer.first_name"
            />
          </div>

          <div v-if="lawyer.middle_name" class="col">
            <BaseInputDisplay
              :label="'Middle Name'"
              :value="lawyer.middle_name"
            />
          </div>

          <div class="col">
            <BaseInputDisplay :label="'Last Name'" :value="lawyer.last_name" />
          </div>
        </div>

        <div class="my-4 row">
          <div class="col">
            <BaseInputDisplay :label="'Company'" :value="lawyer.company_name" />
          </div>

          <div class="col">
            <BaseInputDisplay
              :label="'Contact Number'"
              :value="lawyer.contact_number"
            />
          </div>

          <div class="col">
            <BaseInputDisplay :label="'Email'" :value="lawyer.email" />
          </div>
        </div>
        <div class="my-4 row">
          <div class="col-12">
            <BaseInputDisplay :label="'Address'" :value="lawyer.address" />
          </div>
        </div>
      </div>

      <!-- invoice section -->
      <div class="container">
        <h2 class="mb-5 fs-3 text-dark">Invoice</h2>

        <div id="invoice">
          <BaseCheckboxGroup
            v-model="invoice"
            :options="invoiceOptions"
            :inline="false"
            :label="`invoice`"
          />
        </div>
      </div>

      <div class="d-grid col-4 mt-5 mx-auto">
        <button
          type="button"
          class="btn btn-success rounded-3 text-white my-3"
          @click="submitInvoice"
        >
          CONFIRM REPORT / INVOICE SENT
        </button>
      </div>

      <router-link
        :to="{ name: 'tpd-list' }"
        class="nav-link align-start px-0 mt-2"
      >
        <button type="button" class="btn bg-primary px-3 text-white">
          Bring me back to TPD list
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
import BaseInputDisplay from '@/components/TPD/Base/BaseInputDisplay.vue'
import BaseCheckboxGroup from '@/components/Base/BaseCheckboxGroup.vue'
import InfoBox from '@/components/TPD/Widgets/InfoBox.vue'

export default {
  name: 'TpdCompletedPage',
  components: {
    InfoBox,
    BaseInputDisplay,
    BaseCheckboxGroup,
  },

  data() {
    return {
      assessment: {},
      serviceAndFeesInfo: [],
      invoice: [],
      fee: null,
      lawyer: {},
      invoiceOptions: [
        {
          value: 'IRC',
          label: 'Report Sent',
        },
        {
          value: 'IC',
          label: 'Invoice Sent',
        },
      ],
      pageReady: false,
    }
  },

  mounted() {
    if (this.$store.state.TPDAssessment) {
      this.assessment = this.$store.state.TPDAssessment

      this.assessment.referral.service_requirement.ime_report
        ? this.serviceAndFeesInfo.push('Additional IME report')
        : ''
      this.assessment.referral.service_requirement.mas_report
        ? this.serviceAndFeesInfo.push('Additional MAS report')
        : ''
      let found =
        this.$store.state.TPDTemplates.service_requirement_choices.find(
          (x) =>
            x.service_type ===
            this.assessment.referral.service_requirement.service_type
        )

      if (found) {
        this.serviceAndFeesInfo = this.serviceAndFeesInfo.concat(
          found.service_display_name.split(/[&,]/)
        )
      }
      //map fees
      this.fee = this.$store.state.TPDTemplates.fee_choices.find(
        (x) => x.fee_type === this.assessment.referral.fee_structure_type
      ).fee_display_name

      //map lawyer details
      this.lawyer = this.assessment.referral.referrer

      //map invoice details
      this.assessment.invoice?.invoice_and_report_sent
        ? this.invoice.push('IRC')
        : ''
      this.assessment.invoice?.invoice_completed ? this.invoice.push('IC') : ''
    }

    this.pageReady = true
  },

  methods: {
    async submitInvoice() {
      let value = {
        invoice_completed: false,
        invoice_and_report_sent: false,
      }

      if (this.invoice.length === 2) {
        value.invoice_completed = true
        value.invoice_and_report_sent = true
      } else if (!this.invoice.includes('IC')) {
        value.invoice_and_report_sent = true
      } else if (!this.invoice.includes('IRC')) {
        value.invoice_completed = true
      }

      await this.$store
        .dispatch('putInvoice', {
          id: this.$route.params.id,
          payload: value,
        })
        .then(() => {
          alert('updated invoice information')
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
